// Generated by Framer (716ed5b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/OuhjFBV0O";

const cycleOrder = ["IDugjPFLu"];

const serializationHash = "framer-Z1cum"

const variantClassNames = {IDugjPFLu: "framer-v-1vku26w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({bottomLine, height, id, subheadingText, textColor, width, ...props}) => { return {...props, Hg_jtu8Vt: textColor ?? props.Hg_jtu8Vt ?? "var(--token-6f1336a9-4a56-423a-b8b2-a4a6fb9118ab, rgb(0, 44, 21))", kblNZpCO6: bottomLine ?? props.kblNZpCO6 ?? "var(--token-3070a07a-a5cf-45eb-af38-7f6ebdd9dbe7, rgb(195, 245, 60))", t2D80D7lb: subheadingText ?? props.t2D80D7lb ?? "Subheading"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;subheadingText?: string;textColor?: string;bottomLine?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, t2D80D7lb, Hg_jtu8Vt, kblNZpCO6, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "IDugjPFLu", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vku26w", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"IDugjPFLu"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1v042py"} data-framer-name={"Bottom line"} layoutDependency={layoutDependency} layoutId={"Zm9UbfC0j"} style={{backgroundColor: kblNZpCO6}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-dru90"} data-styles-preset={"OuhjFBV0O"} style={{"--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-Hg_jtu8Vt-UpBD6QTLb))"}}>Subheading</motion.p></React.Fragment>} className={"framer-apm48b"} data-framer-name={"Subheading Text"} layoutDependency={layoutDependency} layoutId={"t4tzdL3zz"} style={{"--extracted-r6o4lv": "var(--variable-reference-Hg_jtu8Vt-UpBD6QTLb)", "--framer-paragraph-spacing": "18px", "--variable-reference-Hg_jtu8Vt-UpBD6QTLb": Hg_jtu8Vt}} text={t2D80D7lb} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-Z1cum[data-border=\"true\"]::after, .framer-Z1cum [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Z1cum.framer-17snehp, .framer-Z1cum .framer-17snehp { display: block; }", ".framer-Z1cum.framer-1vku26w { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-Z1cum .framer-1v042py { bottom: 3px; flex: none; height: 4px; left: calc(49.75124378109455% - 101.99004975124377% / 2); overflow: visible; position: absolute; width: 102%; z-index: 0; }", ".framer-Z1cum .framer-apm48b { flex: none; height: auto; position: relative; white-space: pre; width: auto; z-index: 0; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Z1cum.framer-1vku26w { gap: 0px; } .framer-Z1cum.framer-1vku26w > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-Z1cum.framer-1vku26w > :first-child { margin-top: 0px; } .framer-Z1cum.framer-1vku26w > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 100.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"t2D80D7lb":"subheadingText","Hg_jtu8Vt":"textColor","kblNZpCO6":"bottomLine"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerUpBD6QTLb: React.ComponentType<Props> = withCSS(Component, css, "framer-Z1cum") as typeof Component;
export default FramerUpBD6QTLb;

FramerUpBD6QTLb.displayName = "Typography / Subheading";

FramerUpBD6QTLb.defaultProps = {height: 22, width: 100.5};

addPropertyControls(FramerUpBD6QTLb, {t2D80D7lb: {defaultValue: "Subheading", displayTextArea: false, title: "Subheading text", type: ControlType.String}, Hg_jtu8Vt: {defaultValue: "var(--token-6f1336a9-4a56-423a-b8b2-a4a6fb9118ab, rgb(0, 44, 21)) /* {\"name\":\"Green/900\"} */", title: "Text color", type: ControlType.Color}, kblNZpCO6: {defaultValue: "var(--token-3070a07a-a5cf-45eb-af38-7f6ebdd9dbe7, rgb(195, 245, 60)) /* {\"name\":\"Lime/300\"} */", title: "Bottom line", type: ControlType.Color}} as any)

addFonts(FramerUpBD6QTLb, [...sharedStyle.fonts])